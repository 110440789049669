import React, { useEffect, useState } from "react";
import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";
import { API_BASE_URL } from "../const/config";

// Define types for categories
interface Category {
  id: number;
  name: string;
}

interface Report {
  id: number;
  tableau_url: string;
  report_name: string;
  alias: string;
  description: string;
  category: {
    name: string;
    id: number;
  };
  report_type: string;
}

const HomePage = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState("categories"); // To track the current tab
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    tableauUrl: "",
    reportName: "",
    alias: "",
    description: "",
    category: "",
    reportType: "",
  });

  // Fetch categories from the backend
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/categories`);
      const data = await response.json();
      setCategories(data);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  // Fetch categories from the backend
  const fetchReports = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/reports`);
      const data = await response.json();
      setReports(data);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  // Handle form submission for adding new categories
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Retrieve JWT token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      setError("You need to be logged in to perform this action.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/categories`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify({ name: newCategoryName }),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error creating category");
      }

      setNewCategoryName("");
      setSuccess("Category added successfully!");
      fetchCategories();
    } catch (err: any) {
      setError(err.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchReports();
  }, []);

  // Step navigation logic for Report Wizard
  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const updateFormData = (data: Partial<typeof formData>) => {
    setFormData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            nextStep={nextStep}
            updateFormData={updateFormData}
            formData={formData}
            categories={categories}
          />
        );
      case 2:
        return <StepTwo prevStep={prevStep} formData={formData} />;
      default:
        return (
          <StepOne
            nextStep={nextStep}
            updateFormData={updateFormData}
            formData={formData}
            categories={categories}
          />
        );
    }
  };

  return (
    <div className="flex flex-col gap-2 lg:flex-row">
      <div className="container mx-auto my-10 p-6 shadow-md rounded-lg lg:max-w-[480px] w-full">
        {/* Display existing categories */}
        <h2 className="text-xl font-semibold mb-2">Current Categories:</h2>
        {categories.length > 0 ? (
          <ul className="mb-4">
            {categories.map((category) => (
              <li key={category.id} className="py-1">
                {category.name}
              </li>
            ))}
          </ul>
        ) : (
          <p>No categories found.</p>
        )}

        <h2 className="text-xl font-semibold mb-2">Current Reports:</h2>
        {reports.length > 0 ? (
          <ul className="mb-4">
            {reports.map((report) => (
              <li key={report.id} className="py-1 bg-dark-50">
                <div className="w-full overflow-hidden overflow-ellipsis">
                  <p>
                    <strong>Link:</strong>
                    <a href={report.tableau_url}>{report.tableau_url}</a>
                  </p>
                </div>

                <div>
                  <p>
                    <strong>Name:</strong> {report.report_name}
                  </p>
                  <p>
                    <strong>Alias:</strong> {report.alias}
                  </p>
                  <p>
                    <strong>Category:</strong> {report.category.name}
                  </p>
                  <p>
                    <strong>Report Type:</strong> {report.report_type}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No reports found.</p>
        )}
      </div>

      <div className="container mx-auto my-10 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl font-bold mb-4">
          Report & Category Management
        </h1>

        {/* Tab Navigation */}
        <div className="flex border-b mb-4">
          <button
            className={`px-4 py-2 ${
              currentTab === "categories" ? "border-b-2 border-blue-500" : ""
            }`}
            onClick={() => setCurrentTab("categories")}
          >
            Manage Categories
          </button>
          <button
            className={`px-4 py-2 ${
              currentTab === "report" ? "border-b-2 border-blue-500" : ""
            }`}
            onClick={() => setCurrentTab("report")}
          >
            Report Wizard
          </button>
        </div>

        {/* Tab Panels */}
        {currentTab === "categories" && (
          <div>
            {/* Display success or error messages */}
            {success && <p className="text-green-500 mb-4">{success}</p>}
            {error && <p className="text-red-500 mb-4">{error}</p>}

            {/* Form to add a new category */}
            <h2 className="text-xl font-semibold mb-2">Add New Category:</h2>
            <form onSubmit={handleSubmit} className="flex flex-col mb-6">
              <input
                type="text"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                className="border p-2 mb-4"
                placeholder="Enter category name"
                required
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2"
              >
                Add Category
              </button>
            </form>
          </div>
        )}

        {currentTab === "report" && (
          <div>
            {/* Report Wizard Section */}
            <h2 className="text-xl font-semibold mb-4">
              Embed Tableau Report Wizard
            </h2>
            {renderStep()}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
