import React from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../const/config";

interface StepProps {
  prevStep: () => void;
  formData: {
    tableauUrl: string;
    reportName: string;
    alias: string;
    description: string;
    category: string;
    reportType: string;
  };
}

const StepTwo = ({ prevStep, formData }: StepProps) => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    console.log("Submitted Data:", formData);

    // Retrieve JWT token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      alert("You need to be logged in to submit a report.");
      return;
    }

    // Prepare the report data for submission
    const reportData = {
      tableau_url: formData.tableauUrl,
      report_name: formData.reportName,
      alias: formData.alias,
      description: formData.description,
      category_id: formData.category, // Assuming category is sent as an ID
      report_type: formData.reportType,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/reports/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(reportData),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error submitting report");
      }

      // Navigate to a success or dashboard page after successful submission
      alert("Report submitted successfully!");
      navigate("/"); // Replace with your desired route
    } catch (err: any) {
      console.error("Error submitting report:", err);
      alert(err.message || "An error occurred during report submission.");
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-xl font-bold mb-4">Review and Submit</h2>
      <div className="w-full">
        <strong>Tableau URL:</strong>
        <div className="w-full break-words">{formData.tableauUrl}</div>
      </div>

      <p>
        <strong>Report Name:</strong> {formData.reportName}
      </p>
      <p>
        <strong>Alias:</strong> {formData.alias}
      </p>
      <p>
        <strong>Description:</strong> {formData.description}
      </p>
      <p>
        <strong>Category:</strong> {formData.category}
      </p>
      <p>
        <strong>Report Type:</strong>{" "}
        {formData.reportType === "tableau" ? "Tableau Report" : "Custom Report"}
      </p>
      <div className="flex justify-between mt-4">
        <button onClick={prevStep} className="bg-gray-500 text-white px-4 py-2">
          Back
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-4 py-2"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
