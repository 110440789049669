import React, { useState } from "react";

interface Category {
  id: number;
  name: string;
}

interface StepOneProps {
  nextStep: () => void;
  updateFormData: (data: any) => void;
  formData: {
    tableauUrl: string;
    reportName: string;
    alias: string;
    description: string;
    category: string;
    reportType: string;
  };
  categories: Category[]; // Accept categories as props
}

const StepOne: React.FC<StepOneProps> = ({
  nextStep,
  updateFormData,
  formData,
  categories,
}) => {
  const [selectedReportType, setSelectedReportType] = useState(
    formData.reportType
  );

  const handleReportTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSelectedReportType(value);
    updateFormData({ reportType: value });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    updateFormData({ [e.target.name]: e.target.value });
  };

  return (
    <div>
      <label className="block mb-2">Tableau URL:</label>
      <input
        type="url"
        name="tableauUrl"
        value={formData.tableauUrl}
        onChange={handleChange}
        className="w-full p-2 border mb-4"
        required
      />

      <label className="block mb-2">Report Name:</label>
      <input
        type="text"
        name="reportName"
        value={formData.reportName}
        onChange={handleChange}
        className="w-full p-2 border mb-4"
        required
      />

      <label className="block mb-2">Alias:</label>
      <input
        type="text"
        name="alias"
        value={formData.alias}
        onChange={handleChange}
        className="w-full p-2 border mb-4"
        required
      />

      <label className="block mb-2">Description:</label>
      <textarea
        name="description"
        value={formData.description}
        onChange={handleChange}
        className="w-full p-2 border mb-4"
        rows={4}
        required
      />

      {/* Report Type Selection */}
      <div className="mb-4">
        <label className="block mb-2">Report Type:</label>
        <div>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="tableau"
              checked={selectedReportType === "tableau"}
              onChange={handleReportTypeChange}
              className="mr-2"
            />
            Tableau Report
          </label>
        </div>
        <div>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="custom"
              checked={selectedReportType === "custom"}
              onChange={handleReportTypeChange}
              className="mr-2"
            />
            Custom Report
          </label>
        </div>
      </div>

      {/* Dropdown to select category */}
      <label className="block mb-2">Category:</label>
      <select
        name="category"
        value={formData.category}
        onChange={handleChange}
        className="w-full p-2 border mb-4"
        required
      >
        <option value="">Select a category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>

      <button onClick={nextStep} className="bg-blue-500 text-white px-4 py-2">
        Next
      </button>
    </div>
  );
};

export default StepOne;
